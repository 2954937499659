<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:52:42
 * @Descripttion: 【首页】通知详情
-->
<style lang="scss" scoped>
.notify-detail {
    @include innerPage;
    @include pageHead(-42px);

    .detail {
        &-inner {
            width: 100%;
            height: calc(100% - 196px);
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 34px;
            margin-top: 34px;
            margin-bottom: 40px;
            overflow: hidden;
            overflow-y: auto;

            h3 {
                line-height: 38px;
                color: #232323;
                text-align: center;
            }

            .other-info {
                height: 30px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox(center);

                span {
                    margin: 0 20px;
                }
            }
        }

        &--rich {
            width: 1046px;
            margin: 20px auto 0;

            ::v-deep {
                p {
                    font-size: 16px;
                    line-height: 30px;
                    text-align: justify;
                    color: #434444;
                }

                img {
                    display: block;
                    max-width: 100%;
                    border-radius: 10px;
                    margin: 36px auto;
                }
            }
        }
    }
}
</style>

<template>
    <section class="notify-detail">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="detail-inner">
            <h3 class="bold">{{ detailData.pltea_mes_title }}</h3>
            <div class="detail--rich" v-html="detailData.pltea_mes_content"></div>
        </div>
    </section>
</template>

<script>
import { $msgDetail } from "@api/home"
export default {
    name: 'home_notifyDetail',
    data() {
        return {
            detailData: {}
        }
    },
    created() {
        this.getDetail(this.$route.params.id)
    },
    methods: {
        async getDetail(id) {
            let { data } = await $msgDetail(id);
            this.detailData = data;
        }
    },
}
</script>